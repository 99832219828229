<template>
  <VueFinalModal
    :content-style="{
      width: `${width}px`,
      maxWidth: `${maxWidth}px`,
      ...(height !== 'auto' ? { height: `${height}px` } : {}),
    }"
    :modal-id="name"
    v-bind="$attrs"
  >
    <slot name="header">
      <hcc-modal-header
        v-if="title"
        class="modal-header"
        :variant="variant"
        :title="title"
        @close="handleCancel"
      >
        <template #icons>
          <slot name="header-icons"></slot>
        </template>
      </hcc-modal-header>
    </slot>
    <div class="vfm__body" :class="customClass">
      <hcc-modal-body :class="customClassBody">
        <slot />
      </hcc-modal-body>
    </div>
    <div class="vfm__footer">
      <slot name="footer">
        <hcc-modal-footer
          :disabled="confirmDisableButton"
          :handle-confirm="handleConfirm"
          :handle-cancel="handleCancel"
          :buttons="buttons"
          :variant="variant"
        />
      </slot>
      </div>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';
import HccModalHeader from './HccModalHeader.vue';
import HccModalBody from './HccModalBody.vue';
import HccModalFooter from './HccModalFooter.vue';

export default {
  components: {
    VueFinalModal,
    HccModalHeader,
    HccModalBody,
    HccModalFooter,
  },
  props: {
    name: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      default: null,
    },
    border: {
      type: Boolean,
      default: true,
    },
    customClass: {
      type: [String, Object, Array],
      default: null,
    },
    customClassBody: {
      type: [String, Object, Array],
      default: null,
    },
    buttons: {
      type: Array,
      default: () => ['cancel', 'confirm'],
    },
    width: {
      type: [Number, String],
      default: 500,
    },
    maxWidth: {
      type: Number,
      default: 1200,
    },
    height: {
      type: [Number, String],
      default: 'auto',
    },
    confirmDisableButton: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'alert',
    },
    hideOnConfirm: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm');
      if (this.hideOnConfirm) {
        this.$vfm.close(this.$props.name);
      }
    },
    handleCancel() {
      this.$emit('cancel');
      this.$vfm.close(this.$props.name);
    },
  },
};
</script>
