<script>
export default {
  props: {
    closeToast: {
      type: Function,
      default: () => {},
    },
    t: {
      type: Function,
      default: () => '',
    },
  },
};
</script>

<template>
  <div class="m-4">{{ t('app.message-update') }}</div>
  <div class="flex align-right">
    <button
      class="button outline primary"
      @click="($event) => closeToast && closeToast($event)"
    >
      {{ t('app.accept-update') }}
    </button>
  </div>
</template>
