<template>
  <div class="vfm__header">
    <div class="vfm__header--title">
      <slot name="title">
        <h5 class="text-lg py-[3px]" :class="variant">
          {{ title }}
        </h5>
      </slot>
    </div>

    <button type="button" class="button xs icon" @click="$emit('close')">
      <icon icon="mdi:close" class="icon x2" />
    </button>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  components: { Icon },
  props: {
    title: {
      type: String,
    },
    variant: {
      type: String,
    },
  },
  emits: ['close'],
};
</script>
