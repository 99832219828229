<template>
  <button
    ref="clickBtn"
    class="button"
    :type="type"
    :class="[variant, color, size]"
    :disabled="disabled"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'HccButton',
  props: {
    type: {
      type: String,
      default: 'button',
    },
    variant: {
      type: String,
      default: 'fill',
    },
    color: {
      type: String,
      default: 'secondary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
  },
  methods: {
    blur() {
      this.$refs.clickBtn.blur();
    },
  },
};
</script>
