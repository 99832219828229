export default () => {
  return {
    path: '/administration',
    component: () => import('@modules/DefaultView.vue'),
    meta: {
      priority: 12,
      forAuth: true,
      site: {
        name: 'app.campaigns',
      },
    },
    children: [
      {
        path: '',
        name: 'administration',
        redirect: {
          name: 'owner-users',
        },
        meta: {
          priority: 12.1,
        },
      },
      {
        path: 'users',
        name: 'owner-users',
        component: () => import('@modules/administration/views/Users.vue'),
        meta: {
          priority: 12.2,
          forAuth: true,
          layout: 'navigation-layout',
          site: {
            name: 'app.users',
          },
          requiredPermissions: ['users_read', 'users_write', 'users_write'],
        },
      },
      {
        path: '/companies',
        name: 'companies',
        component: () => import('@modules/administration/views/Companies.vue'),
        meta: {
          priority: 12.3,
          forAuth: true,
          site: {
            name: 'app.company',
          },
        },
      },
    ],
  };
};
